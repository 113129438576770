/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/no-unescaped-entities */
import Head from "next/head";
import Link from "next/link";
import Footer from "../components/Footer";
import GameContainer from "../containers/GameContainer";
import { isTWA } from "../utils/helper";
import { useContext } from "react";
import { ThemeContext, DARK } from "../utils/theme";

export default function MainPage() {
  const theme = useContext(ThemeContext);
  return (
    <div>
      <Head>
        <title>
          Play Free Sudoku online - Solve Sudoku Puzzles from Easy to Expert
        </title>
        <meta
          name="description"
          content="Play free Sudoku online from Easy to Expert level on Sudoku.komeilmehranfar.com. Install the PWA to play Sudoku offline."
        ></meta>
        <meta
          name="twitter:description"
          content="Play Free Sudoku Online - Solve Sudoku Puzzles from Easy to Expert | Sudoku - Komeil Mehranfar"
        />
        <meta
          name="og:description"
          content="Play Free Sudoku Online - Solve Sudoku Puzzles from Easy to Expert | Sudoku - Komeil Mehranfar"
        />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://sudoku.komeilmehranfar.com" />
        <meta name="twitter:title" content="Sudoku" />
        <meta
          name="twitter:image"
          content="https://sudoku.komeilmehranfar.com/Icon-512.png"
        />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Sudoku" />

        <meta property="og:site_name" content="Sudoku" />
        <meta property="og:url" content="https://sudoku.komeilmehranfar.com" />
        <meta
          property="og:image"
          content="https://sudoku.komeilmehranfar.com/Icon-512.png"
        />
        <link rel="canonical" href="https://sudoku.komeilmehranfar.com/" />
      </Head>
      <GameContainer />
      {isTWA() ? null : (
        <div>
          <div
            className="container py-5"
            style={{
              fontSize: 18,
              color: theme?.state === DARK ? "white" : "#385d81",
            }}
          >
            <section className="py-3 font-weight-light">
              <h1 className="font-weight-bold">Play Free Online Sudoku!</h1>
              <p>
                <strong>Sudoku</strong> is one of the most preferred puzzle
                games of perpetuity. The objective of <strong>Sudoku</strong> is
                to fill a 9 × 9 grid with numbers so that each row, column, as
                well as 3 × 3 section, include all of the figures in between 1
                and 9. As a logic puzzle, Sudoku is also an excellent brain
                game. If you play <bold>Sudoku</bold> daily, you will certainly
                soon start to see enhancements in your focus as well as overall
                brain power. Start a game now. Within no time at all{" "}
                <strong>Sudoku</strong> free puzzles will be your favored online
                game.
              </p>
            </section>
            <section className="py-3 font-weight-light">
              <h2 className="font-weight-bold">About Sudoku</h2>
              <p>
                The popular Japanese puzzle game <strong>Sudoku</strong> is
                based on the rational positioning of numbers. An online game of
                logic, Sudoku doesn't need any kind of computation nor unique
                mathematics skills; all that is needed are brains as well as
                concentration.
              </p>
            </section>
            <section className="py-3 font-weight-light">
              <h2 className="font-weight-bold">Exactly How to play Sudoku</h2>
              <p>
                The goal of <strong>Sudoku</strong> is to fill in a 9 × 9 grid
                with figures to make sure that each column, row, and also 3 × 3
                box include the numbers between 1 to 9. At the start of the
                game, the 9 × 9 grid will certainly have several of the squares
                filled in. Your job is to utilize logic to complete the missing
                out on figures and finish the grid. Don't neglect, a move is
                incorrect if:
              </p>
              <ul>
                <li>
                  - Any type of row consists of greater than among the same
                  number from 1 to 9
                </li>
                <li>
                  - Any type of column consists of greater than among the very
                  same number from 1 to 9
                </li>
                <li>
                  - Any 3 × 3 grid has more than one of the same number from 1
                  to 9
                </li>
              </ul>
            </section>
            <section className="py-3 font-weight-light">
              <h2 className="font-weight-bold">Sudoku Tips</h2>
              <p>
                <strong>Sudoku</strong> is a fun puzzle game when you master it.
                At the same time, learning to play Sudoku can be a little bit
                intimidating for beginners. So, if you are a full novice, here
                are a few <bold>Sudoku</bold> ideas that you can use to boost
                your Sudoku skills.
              </p>
              <ul>
                <li>
                  Tip 1: Search for rows, columns of 3 × 3 sections which
                  contain 5 or more numbers. Overcome the remaining empty cells,
                  trying the numbers that have actually not been made use of. In
                  a lot of cases, you will certainly discover numbers that can
                  only be placed in one position considering the other numbers
                  that are already in its row, column, and 3 × 3 grid.
                </li>
                <li className="mt-3">
                  Tip 2: Break the grid up aesthetically into 3 columns and 3
                  rows. Each huge column will certainly have 3, 3 × 3 grids and
                  also each row will have 3, 3 × 3 grids. Now, try to find
                  columns or grids that have 2 of the same number.
                  Realistically, there have to be a third duplicate of the very
                  same number in the only staying 9-cell area. Take a look at
                  each of the continuing to be 9 settings and also see if you
                  can locate the location of the absent number.
                </li>
              </ul>
              <p>
                Now that you recognize a little much more regarding{" "}
                <strong>Sudoku</strong>, play and also appreciate this free
                online puzzle game.
              </p>
            </section>
            <section className="py-3 font-weight-light">
              <h2 className="font-weight-bold">Sudoku Difficulties</h2>
              <p>
                Here in{" "}
                <strong>
                  <Link href="https://sudoku.komeilmehranfar.com" legacyBehavior>
                    <a>Sudoku.komeilmehranfar.com</a>
                  </Link>
                </strong>{" "}
                we have 4 difficulties. <bold>Sudoku Puzzles</bold> from{" "}
                <bold>Easy</bold> to <bold>Expert</bold>
              </p>
              <div className="col-12 col-sm-4 px-0 ">
                <Link legacyBehavior href="/easy">
                  <a className="text-white btn-primary-1">Easy</a>
                </Link>
              </div>
              <div className="col-12 col-sm-4 px-0 mt-3">
                <Link legacyBehavior href="/medium">
                  <a className="text-white btn-primary-1">Medium</a>
                </Link>
              </div>
              <div className="col-12 col-sm-4 px-0 mt-3">
                <Link legacyBehavior href="/hard">
                  <a className="text-white btn-primary-1">Hard</a>
                </Link>
              </div>
              <div className="col-12 col-sm-4 px-0 mt-3">
                <Link legacyBehavior href="/expert">
                  <a className="text-white btn-primary-1">Expert</a>
                </Link>
              </div>
            </section>
           
          </div>

          <Footer />
        </div>
      )}{" "}
    </div>
  );
}
